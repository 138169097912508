.container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  align-items: flex-start;
  height: fit-content;
  margin-top: 20px;
  gap: 10px;
}

.titleWrapper {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  min-width: 60px;
  min-height: 20px;
}

.title {
  width: 80px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.11px;
  line-height: 17px;
  display: flex;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  min-width: 250px;
  gap: 7px;
}

.text {
  word-break: break-all;
  font-size: 16px;
  letter-spacing: 0.1px;
  display: flex;
  line-height: 17px;
}
