@font-face {
  font-family: Overpass;
  src: url("Overpass-Regular.236aedd2.woff2") format("woff2");
}

@font-face {
  font-family: Overpass;
  font-style: normal;
  font-weight: 600;
  src: url("Overpass-SemiBold.8e229d65.woff2") format("woff2");
}

:root {
  font-family: Overpass, sans-serif;
}

body {
  margin: 0;
}

html {
  --body: #fff;
  --web3-name: #2d8b00;
  --main-heading-background: #eaeaea;
  --header-secondary: #2d8b00;
  --header-bottom: #d8d8d8;
  --search-bar: #d8d8d8;
  --text: #0d0d0d;
  --header-text: #f3f3f3;
  --take-tour: #2d9403;
  --dark-grey: #322b2b;
  --link-disabled-rgb: 13 13 13;
  --fetch-rgb: 45 139 0;
  --gradient-1-3-rgb: 255 255 255;
  --gradient-1-2-rgb: 45 139 0;
  --separator-rgb: 69 69 69;
  --gradient-1-1-rgb: 45 139 0;
  --gradient-2-1-rgb: 37 102 6;
  --gradient-2-2-rgb: 22 48 10;
  --gradient-2-3-rgb: 13 13 13;
  --modal-bg: var(--header-text);
  --black-rgb: 0 0 0;
  --fetch-button: #f3f3f3;
  --fetch-button-text: #2d8b00;
  --fetch-button-border-disabled-rgb: 223 134 1;
  --fetch-button-text-disabled-rgb: 45 139 0;
  --light-green: #678956;
  --extension-border-rgb: var(--black-rgb);
  --contents: #f3f3f3;
  --modal-btn-disabled-rgb: 223 134 1;
  --footer: #f3f3f3;
  --inactive-tab: #eaeaea;
  --inactive-text: #c7c7c7;
  --button-primary-background: #f3f3f3;
  --button-primary-border: #df8601;
  --button-primary-text: #df8601;
  --select: #df8601;
  --select-text: #fff;
  --options-background: #f3f3f3;
  --modal-background: #f3f3f3;
  --modal-border: #3dc000;
  --inactive-payment-tab: #a3c791;
  --color-shadow-rgb: 0 0 0;
}

html.dark {
  --body: #000;
  --web3-name: #47e000;
  --main-heading-background: #242424;
  --header-secondary: #256606;
  --header-bottom: #454545;
  --search-bar: #d8d8d8;
  --text: #f3f3f3;
  --header-text: #f3f3f3;
  --take-tour: #2d7011;
  --link-disabled-rgb: 255 255 255;
  --footer: #181818;
  --modal-bg: #14280b;
  --fetch-button: #2d1f0b;
  --fetch-button-text: #f3f3f3;
  --fetch-button-text-disabled-rgb: 223 134 1;
  --extension-border-rgb: 255 255 255;
  --contents: #0d0d0d;
  --light-green: #3a5530;
  --inactive-tab: #6b6f69;
  --inactive-text: #f3f3f3;
  --inactive-payment-tab: #6b6f69;
  --button-primary-background: #2d1f0b;
  --button-primary-text: #f3f3f3;
  --select-text: #f3f3f3;
  --options-background: #2d1f0b;
  --modal-background: #14280b;
}

.Ra0IAa_container {
  min-height: 100vh;
  max-width: 100vw;
  background-color: var(--body);
  color: var(--text);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Overpass, sans-serif;
  display: flex;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.P2iXnG_footer {
  width: 100%;
  min-height: 34px;
  background: var(--footer);
  border-top: 1px solid var(--take-tour);
  place-content: center;
  margin-top: auto;
  display: flex;
}

.P2iXnG_content {
  max-width: 766px;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  display: flex;
}

@media (max-width: 700px) {
  .P2iXnG_content {
    width: 100%;
    flex-direction: column;
  }
}

.P2iXnG_navMenu {
  align-items: center;
  gap: 10px;
  display: flex;
}

@media (max-width: 700px) {
  .P2iXnG_navMenu {
    width: 100%;
    border-bottom: 1px solid rgb(var(--link-disabled-rgb) / 30%);
    place-content: center;
    padding: 7px 0;
    display: flex;
  }
}

.P2iXnG_navLink {
  color: var(--web3-name);
  line-height: 20px;
  text-decoration: none;
}

.P2iXnG_navLinkActive {
  opacity: .5;
}

.P2iXnG_copyright {
  color: var(--text);
  background: url("built_on_kilt_light.1d35b87a.svg") 100% / 25% no-repeat;
  margin: 0;
  padding: 7px 90px 0 0;
  font-size: 10px;
  line-height: 20px;
}

html.dark .P2iXnG_copyright {
  background: url("built_on_KILT_dark.c009c8c3.svg") 100% / 25% no-repeat;
}

@media (max-width: 700px) {
  .P2iXnG_copyright {
    margin: 0 0 7px;
  }
}

._3aV65G_container {
  width: 100%;
}

._3aV65G_headingContainer {
  background: var(--main-heading-background);
  border-top: 10px rgb(var(--fetch-rgb) / 50%) solid;
  box-shadow: 0 2px 4px 0 rgb(var(--black-rgb) / 35%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

html.dark ._3aV65G_headingContainer {
  border-top: 10px rgb(var(--gradient-2-1-rgb) / 50%) solid;
}

._3aV65G_headingContent {
  width: 90%;
  max-width: 740px;
  align-items: center;
  margin: 0;
  display: flex;
}

._3aV65G_mainContainer {
  width: 100%;
  background: linear-gradient( 180deg, rgb(var(--gradient-1-1-rgb) / 30%) 0, rgb(var(--gradient-1-2-rgb) / 10%) 60px, rgb(var(--gradient-1-3-rgb) / 0%) 185px );
  border-top: 10px rgb(var(--fetch-rgb) / 50%) solid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

html.dark ._3aV65G_mainContainer {
  background: linear-gradient( 180deg, rgb(var(--gradient-2-1-rgb) / 68%) 0, rgb(var(--gradient-2-2-rgb) / 60%) 60px, rgb(var(--gradient-2-3-rgb) / 0%) 185px );
  border-top: 10px rgb(var(--gradient-2-1-rgb) / 50%) solid;
}

._3aV65G_mainContent {
  max-width: 740px;
  width: 90%;
  flex-direction: column;
  padding: 36px 0 100px;
  display: flex;
}

._9yhFLa_container {
}

._9yhFLa_headingContainer {
  height: 80px;
}

._9yhFLa_termsHeading {
  letter-spacing: .17px;
  font-size: 24px;
  font-weight: 300;
}

._9yhFLa_termsContainer {
}

._9yhFLa_terms {
  letter-spacing: .1px;
  font-size: 14px;
  line-height: 16px;
}

._9yhFLa_spacedHeading {
  margin-top: 40px;
}

._9yhFLa_bulletList {
  list-style-type: circle;
}

._9yhFLa_anchor {
  color: var(--web3-name);
}

.ZEJ9SW_header {
  background-color: var(--body);
  width: 100vw;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.ZEJ9SW_logo {
  width: 70px;
  height: 30px;
  background: url("w3n_logo_light.ba9613e5.svg") no-repeat;
  margin-block: 15px;
  margin-inline-start: calc(50% + min(90%, 740px) / -2);
}

html.dark .ZEJ9SW_logo {
  background: url("w3n_logo_dark.330867a9.svg") 0 no-repeat;
}

.ZEJ9SW_headingTextContainer {
  background-color: var(--header-secondary);
  width: 100%;
  border-block-end: 10px solid rgb(var(--separator-rgb) / 25%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: .25rem;
  display: flex;
}

.ZEJ9SW_text {
  max-width: 740px;
  width: 90%;
  color: var(--header-text);
  letter-spacing: .11px;
  height: 34px;
  align-items: center;
  font-size: 16px;
  line-height: 17px;
  display: flex;
}

.WWsiTG_switch {
  width: 22px;
  height: 22px;
  cursor: pointer;
  background: url("switch2dark.85ea37f1.svg") center no-repeat;
  border: none;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  position: absolute;
  top: 25px;
  right: 30px;
}

html.dark .WWsiTG_switch {
  background: url("switch2light.0a22946a.svg") center no-repeat;
}

._5JMN_a_container {
}

._5JMN_a_search {
  height: 144px;
}

._5JMN_a_searchBar {
  max-width: 740px;
  width: 90%;
  height: 32px;
  background-color: var(--search-bar);
  box-shadow: inset 0 2px 2px 0 rgb(var(--black-rgb) / 35%);
  border-radius: 16px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  display: flex;
}

._5JMN_a_button {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url("magnifier.8d61e0b1.svg") no-repeat;
  border: none;
  margin-right: 5px;
}

._5JMN_a_input {
  width: 85%;
  color: #000;
  letter-spacing: .26px;
  background: none;
  border: none;
  font-size: 14px;
  line-height: 22px;
}

._5JMN_a_input:focus {
  outline: none;
}

._5JMN_a_infoText {
  color: var(--text);
  letter-spacing: .11px;
  max-width: 740px;
  width: 90%;
  font-size: 16px;
  line-height: 17px;
}

._5JMN_a_infoTextAddress {
  color: var(--text);
  letter-spacing: .1px;
  text-align: right;
  opacity: .8;
  padding-right: 5px;
  font-size: 14px;
  line-height: 16px;
}

._5JMN_a_main {
}

html.dark ._5JMN_a_main {
  background: linear-gradient( 180deg, rgb(var(--gradient-2-1-rgb) / 68%) 0, rgb(var(--gradient-2-2-rgb) / 60%) 60px, rgb(var(--gradient-2-3-rgb) / 0%) 185px );
  border-top: 10px rgb(var(--gradient-2-1-rgb) / 50%) solid;
}

._5JMN_a_results {
  padding: 0;
}

._5JMN_a_noWeb3name {
  margin-top: 40px;
}

._5JMN_a_title {
  width: 95px;
  letter-spacing: .1px;
  text-align: left;
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
}

._5JMN_a_infoContainer {
  max-width: 740px;
  width: 87%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

._5JMN_a_modeContainer {
  width: 100%;
  letter-spacing: .1px;
  text-align: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: -10px;
  margin-bottom: 50px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  position: relative;
}

._5JMN_a_availableActiveMode {
  height: 35px;
  width: 180px;
  background-color: var(--header-secondary);
  box-shadow: -3px 6px 8px 0 rgb(var(--black-rgb) / 25%);
  color: var(--header-text);
  z-index: 10;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 160px;
}

._5JMN_a_availableInactiveMode {
  background-color: var(--inactive-tab);
  color: var(--inactive-text);
  opacity: .5;
  z-index: 0;
}

._5JMN_a_takenActiveMode {
  height: 35px;
  width: 180px;
  background-color: var(--header-secondary);
  box-shadow: -3px 6px 8px 0 rgb(var(--black-rgb) / 25%);
  color: var(--header-text);
  z-index: 10;
  border-bottom-left-radius: 13px;
  border-bottom-right-radius: 13px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

._5JMN_a_takenInactiveMode {
  background-color: var(--inactive-tab);
  color: var(--inactive-text);
  opacity: .5;
  z-index: 0;
}

.tTJEqG_container {
  width: 100%;
  height: fit-content;
  flex-flow: wrap;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;
  display: flex;
}

.tTJEqG_titleWrapper {
  min-width: 60px;
  min-height: 20px;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.tTJEqG_title {
  width: 80px;
  letter-spacing: .11px;
  font-size: 16px;
  font-weight: bold;
  line-height: 17px;
  display: flex;
}

.tTJEqG_wrapper {
  min-width: 250px;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 7px;
  display: flex;
}

.tTJEqG_text {
  word-break: break-all;
  letter-spacing: .1px;
  font-size: 16px;
  line-height: 17px;
  display: flex;
}

@keyframes kSECIa_rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.kSECIa_spinner {
  background: url("spinner_light.4439aa2a.svg") center / contain no-repeat;
  animation: kSECIa_rotation 1.5s linear infinite;
}

html.dark .kSECIa_spinner {
  background-image: url("spinner_dark.e5207b42.svg");
}

.aa4haa_cardContainer {
  background-color: var(--header-secondary);
  width: 100%;
  box-shadow: 0 6px 8px 0 rgb(var(--black-rgb) / 25%);
  border-radius: 16px;
  flex-direction: column;
  margin-bottom: 20px;
  display: flex;
}

.aa4haa_expand {
  color: #fff;
  text-align: left;
  cursor: pointer;
  height: 36px;
  background: url("open_chevron.870efc3c.svg") right 7px center / 20px no-repeat;
  border: none;
  padding-left: 20px;
  font-size: 16px;
}

.aa4haa_expand[aria-expanded="true"] {
  background-image: url("close_chevron.936c4db1.svg");
}

.aa4haa_cardContents {
  background-color: var(--contents);
  border-radius: 5px 5px 15px 15px;
  margin: 0 4px 4px;
  padding: 28px 16px 16px;
}

.-GphNG_endpointWrapper {
}

.-GphNG_type {
  overflow-wrap: break-word;
  letter-spacing: 0;
  color: #fff;
  margin: 5px 0 5px 22px;
  font-size: 16px;
  font-weight: normal;
  line-height: 17px;
}

.-GphNG_endpoint {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 13px 18px;
  display: flex;
}

@media (max-width: 700px) {
  .-GphNG_endpoint {
    flex-direction: column;
    gap: 10px;
  }
}

.-GphNG_fetched {
  flex-direction: column;
  align-items: flex-start;
  padding: 18px;
}

.-GphNG_urlContainer {
  width: 70%;
  justify-content: flex-start;
  gap: 5px;
  margin-left: 8px;
  margin-right: 10px;
  display: flex;
}

.-GphNG_fetchedUrlContainer {
  width: 100%;
  max-width: none;
  align-items: flex-start;
}

.-GphNG_url {
  letter-spacing: .11px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Overpass, sans-serif;
  font-size: 16px;
  line-height: 17px;
  display: block;
  overflow: hidden;
}

.-GphNG_button {
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: .11px;
  color: var(--fetch-button-text);
  background-color: var(--fetch-button);
  width: 124px;
  height: 28px;
  border: 3px solid #df8601;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 17px;
  display: inline-flex;
  position: relative;
}

.-GphNG_transferButton {
  background: url("not_ok.8dfd6b00.svg") .25rem 50% / 1rem no-repeat;
}

.-GphNG_fetch {
}

.-GphNG_close {
  align-self: flex-end;
}

.-GphNG_button:disabled {
  border: 3px solid rgba(var(--fetch-button-border-disabled-rgb) / 50%);
  color: rgba(var(--fetch-button-text-disabled-rgb) / 50%);
}

.-GphNG_loader {
  height: 18px;
  width: 18px;
  position: absolute;
  right: 2px;
}

.-GphNG_transfer {
  inline-size: 100%;
  background: url("not_ok.8dfd6b00.svg") 0 0 / 1rem no-repeat;
  margin: .5rem;
  padding-inline-start: 1.25rem;
}

.-GphNG_seperator {
  border: 1px dashed var(--web3-name);
  width: 100%;
  opacity: .5;
  margin-top: 18px;
}

.-GphNG_didDocument {
  justify-content: flex-start;
  margin-top: 20px;
}

.-GphNG_wrapper {
}

.-GphNG_endpoints {
  width: 620px;
}

.-GphNG_text {
}

.-GphNG_title {
}

.-GphNG_titleWrapper {
  align-self: start;
}

.-GphNG_credentials {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

.-GphNG_result {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  display: flex;
}

.-GphNG_credential {
}

.-GphNG_credential:not(:last-child) {
  border-bottom: 1px solid rgba(var(--gradient-1-2-rgb) / 30%);
}

.-GphNG_error {
}

.d90ZcW_copy {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background: url("copy2clipboard_light.1ba7d5e2.svg") center / contain no-repeat;
  border: none;
  flex-shrink: 0;
  padding: 0;
}

.d90ZcW_copy:disabled {
  cursor: auto;
  background: url("copied_light.9162c52f.svg") center / contain no-repeat;
}

html.dark .d90ZcW_copy {
  background: url("copy2clipboard_dark.104f5d2f.svg") center / contain no-repeat;
}

html.dark .d90ZcW_copy:disabled {
  background: url("copied_dark.708e3765.svg") center / contain no-repeat;
}

.AG92Ra_container {
  padding-left: 10px;
}

.AG92Ra_heading {
  margin: 0 0 20px;
  font-size: 20px;
}

.AG92Ra_definitions {
  margin: 0;
}

.AG92Ra_definition {
  width: 90%;
  gap: 20px;
  margin: 0 0 20px;
  display: flex;
}

.AG92Ra_title {
  width: 20%;
  min-width: 90px;
  letter-spacing: .11px;
  text-align: left;
  word-break: normal;
  align-self: center;
  font-family: Overpass, sans-serif;
  font-size: 16px;
  line-height: 17px;
}

.AG92Ra_description {
  letter-spacing: .11px;
  word-break: break-word;
  margin: 0;
  font-size: 16px;
  line-height: 17px;
  display: block;
}

.AG92Ra_valid {
  width: 24px;
  height: 24px;
  margin: 0;
}

.AG92Ra_spinner {
  height: 16px;
  width: 16px;
  display: inline-block;
}

.AG92Ra_anchor {
  color: var(--web3-name);
  text-decoration: none;
}

.-cPe0a_definitions {
}

.-cPe0a_definition {
}

.-cPe0a_title {
}

.-cPe0a_description {
}

.-cPe0a_invalid {
}

.WIMfdG_button {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: url("tool_tip_light.abaeea42.svg") center / contain no-repeat;
  border: none;
  position: relative;
}

.WIMfdG_wrapper {
  width: 440px;
  z-index: 20;
  display: flex;
  position: absolute;
  top: 30px;
  right: -15px;
}

.WIMfdG_right {
  top: 20px;
  left: -16px;
}

@media (max-width: 480px) {
  .WIMfdG_wrapper {
    width: 200px;
  }
}

.WIMfdG_text {
  border: 2px solid var(--web3-name);
  background-color: var(--modal-bg);
  color: var(--text);
  box-shadow: 0 6px 8px 0 rgba(var(--black-rgb) / 25%);
  letter-spacing: .11px;
  text-align: left;
  border-radius: 8px;
  margin: 1em 0 0;
  padding: 15px;
  font-size: 16px;
  line-height: 22px;
  position: relative;
}

.WIMfdG_text:before {
  content: "";
  border-style: solid;
  border-color: var(--web3-name) transparent;
  width: 0;
  border-width: 0 13px 12px;
  display: block;
  position: absolute;
  inset: -12px 10px auto auto;
}

.WIMfdG_right .WIMfdG_text:before {
  left: 10px;
  right: auto;
}

.WIMfdG_text:after {
  content: "";
  border-style: solid;
  border-color: var(--modal-bg) transparent;
  width: 0;
  border-width: 0 12px 10px;
  display: block;
  position: absolute;
  inset: -8px 11px auto auto;
}

.WIMfdG_right .WIMfdG_text:after {
  left: 11px;
  right: auto;
}

.WvW8Pa_container {
  align-items: center;
}

.WvW8Pa_title {
}

.WvW8Pa_text {
}

.WvW8Pa_error {
  background: url("attention.ae257af9.svg") 100% no-repeat;
  padding-right: 24px;
  font-style: italic;
  font-weight: bold;
}

.WvW8Pa_wrapper {
  align-items: center;
}

.WvW8Pa_titleWrapper {
  align-items: center;
}

.Nu1OmW_container {
  margin: 0;
}

.Nu1OmW_title {
  min-width: 105px;
  align-self: center;
}

.Nu1OmW_wrapper {
  height: 100%;
  align-items: center;
}

.Nu1OmW_text {
  letter-spacing: .17px;
  font-size: 24px;
  line-height: 26px;
}

.Nu1OmW_italic {
  font-style: italic;
}

.Nu1OmW_error {
}

.D6ezSq_container {
  margin-top: 20px;
}

.D6ezSq_title {
  min-width: 105px;
  display: flex;
}

.D6ezSq_text {
  word-break: normal;
}

.D6ezSq_wrapper {
  max-width: 620px;
  width: 90%;
  flex-direction: column;
  display: flex;
}

.D6ezSq_anchor {
  width: 80%;
  color: var(--web3-name);
  word-break: break-all;
  text-decoration: none;
  display: inline-flex;
}

.ZMuBmW_container {
  margin-top: 40px;
  padding: 0;
}

.IvzLRW_container {
}

.IvzLRW_title {
}

.IvzLRW_text {
}

.IvzLRW_wrapper {
  justify-content: space-between;
}

.IvzLRW_accountsContainer {
  max-width: 520px;
  width: 80%;
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.IvzLRW_titleWrapper {
}

.lu3JAa_container {
  height: 195px;
  width: 100vw;
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 45px;
  display: flex;
}

.lu3JAa_contents {
  max-width: 740px;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  gap: 45px;
  display: flex;
}

.lu3JAa_title {
  letter-spacing: .11px;
  font-size: 16px;
  font-weight: bold;
  line-height: 17px;
}

.lu3JAa_text {
  word-break: normal;
  letter-spacing: .11px;
  font-size: 16px;
  line-height: 17px;
}

.lu3JAa_anchor {
  color: var(--web3-name);
  text-decoration: none;
}

.jjjniW_container {
  margin-top: 20px;
}

.jjjniW_title {
  min-width: 105px;
  align-self: start;
  margin-bottom: 2px;
  display: flex;
}

.jjjniW_text {
  color: var(--text);
  letter-spacing: .11px;
  font-size: 16px;
  line-height: 17px;
}

.jjjniW_topText {
  margin: 0;
}

.jjjniW_bottom {
  justify-content: space-between;
  margin: 44px 0 0;
  display: flex;
}

.jjjniW_bottomText {
  margin: 0;
}

.jjjniW_anchor {
  color: var(--web3-name);
}

.jjjniW_checkoutLegal {
  flex-direction: column;
  align-items: flex-start;
  margin-block-start: .5rem;
  display: flex;
}

.jjjniW_wrapper {
  width: 620px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
}

.jjjniW_claimContainer {
  width: 100%;
  min-height: 36px;
  justify-content: center;
  margin-top: 20px;
  position: relative;
}

.jjjniW_controlBtn {
}

.jjjniW_claimContents {
}

.jjjniW_steps {
  margin: 0;
  padding-left: 16px;
}

.jjjniW_step {
}

.jjjniW_step::marker {
  font-weight: normal;
}

.jjjniW_selectAccount {
  margin-bottom: 64px;
}

.jjjniW_stepsHeading {
  margin: 0;
}

.jjjniW_btn {
  box-sizing: border-box;
  height: 28px;
  max-width: 320px;
  width: 80%;
  border: 3px solid var(--button-primary-border);
  background-color: var(--button-primary-background);
  color: var(--button-primary-text);
  letter-spacing: .11px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 15px;
  padding-block: 4px;
  font-family: Overpass, sans-serif;
  font-size: 16px;
  line-height: 17px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.jjjniW_btn + .jjjniW_btn {
  margin-top: .5rem;
}

.jjjniW_btn:disabled, .jjjniW_btn[aria-disabled="true"], .jjjniW_loaded {
  color: rgb(var(--modal-btn-disabled-rgb) / 50%);
  border: 3px solid rgb(var(--modal-btn-disabled-rgb) / 50%);
  cursor: not-allowed;
}

@media (max-width: 420px) {
  .jjjniW_btn {
    font-size: 12px;
  }

  .jjjniW_step {
    font-size: 14px;
  }
}

.jjjniW_spinner {
  width: 1rem;
  height: 1rem;
  display: block;
  position: absolute;
  top: .2rem;
  right: .25rem;
}

.jjjniW_loaded {
  background: url("ok.57184981.svg") 99% / 1rem no-repeat;
  margin: 0;
}

.jjjniW_select {
  z-index: 1;
  min-height: 28px;
  max-width: 320px;
  width: 80%;
  cursor: pointer;
  background: url("open_triangle.7eac21f4.svg") no-repeat top 11px right 10px / 9px 6px, var(--select);
  color: var(--select-text);
  border-radius: 16px;
  flex-direction: column;
  display: flex;
  position: absolute;
}

.jjjniW_select[aria-disabled="true"] {
  opacity: .4;
  pointer-events: none;
}

.jjjniW_selectShow {
  background: url("close_triangle.3c89a2d8.svg") no-repeat top 11px right 10px / 9px 6px, var(--select);
}

.jjjniW_options {
  background: var(--options-background);
  color: var(--text);
  border-radius: 5px 5px 15px 15px;
  flex-direction: column;
  margin: 0 3px 3px;
  padding-left: 0;
  list-style-position: inside;
  display: flex;
}

.jjjniW_ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jjjniW_selectText {
  letter-spacing: .11px;
  width: 90%;
  text-align: left;
  height: 20px;
  margin-top: 5px;
  padding-left: 17px;
  font-size: 16px;
  line-height: 17px;
}

.jjjniW_option {
  height: 30px;
  align-items: center;
  margin: 0;
  padding-left: 14px;
  list-style-type: none;
  display: flex;
}

.jjjniW_option:last-child {
  border-radius: 0 0 15px 15px;
}

.jjjniW_option:not([aria-disabled="true"]):hover {
  background-color: #df860180;
}

.jjjniW_option[aria-disabled="true"] {
  cursor: not-allowed;
  font-style: italic;
}

.jjjniW_optionText {
  width: 90%;
  text-align: left;
  letter-spacing: .11px;
  font-size: 16px;
  line-height: 17px;
}

.jjjniW_error {
  text-align: center;
  color: red;
}

.ROY_zW_tabContainer {
  text-align: center;
  z-index: 10;
  flex-direction: row;
  margin-bottom: -5px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
}

.ROY_zW_tabItem {
  background-color: var(--contents);
  width: 50%;
  cursor: pointer;
  color: var(--web3-name);
  border: none;
  border-radius: 20px 20px 0 0;
  padding: .3em;
  font-size: large;
}

.ROY_zW_activeTabItem {
  background-color: var(--inactive-payment-tab);
}

.ROY_zW_tabItem:hover {
  filter: brightness(85%);
}

.qNhs6W_container {
  margin: 10px;
  display: block;
}

.o3V_MW_container {
  background-color: rgb(var(--color-shadow-rgb) / 10%);
  backdrop-filter: blur(5px);
  z-index: 30;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.o3V_MW_modal {
  max-width: 380px;
  width: 80%;
  background-color: var(--modal-background);
  box-shadow: 0 6px 8px 0 rgb(var(--color-shadow-rgb) / 25%);
  border: 2px solid var(--modal-border);
  border-radius: 12px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
  position: relative;
}

.o3V_MW_heading {
  color: var(--text);
  letter-spacing: .1px;
  justify-content: center;
  margin-top: 30px;
  font-size: 24px;
  font-weight: 300;
  line-height: 17px;
  display: flex;
}

.o3V_MW_text {
  color: var(--text);
  letter-spacing: .1px;
  text-align: center;
  width: 70%;
  font-size: 16px;
  line-height: 17px;
}

.o3V_MW_web3name {
  font-size: 24px;
  line-height: 26px;
}

.o3V_MW_status {
  margin-top: 10px;
}

.o3V_MW_btn {
  color: var(--button-primary-text);
  width: 80%;
  height: 30px;
  border: 3px solid var(--button-primary-border);
  text-transform: uppercase;
  cursor: pointer;
  background: var(--button-primary-background);
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  margin: 24px 0;
  font-size: 16px;
  line-height: 17px;
  display: inline-flex;
  position: relative;
}

.o3V_MW_btn:disabled {
  color: rgb(var(--modal-btn-disabled-rgb) / 50%);
  border: 3px solid rgb(var(--modal-btn-disabled-rgb) / 50%);
  cursor: none;
}

.o3V_MW_step {
  color: #fff;
  letter-spacing: 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 16px;
  position: absolute;
}

.o3V_MW_successIcon {
  height: 70px;
  width: 70px;
  background: url("ok.57184981.svg") center / 80% no-repeat, #fff;
  border-radius: 50%;
}

.o3V_MW_errorIcon {
  background: url("not_ok.8dfd6b00.svg") center / 80% no-repeat, #fff;
}

@keyframes o3V_MW_rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.o3V_MW_spinner {
  width: 70px;
  height: 70px;
}

.GCP1rq_container {
  margin-top: 20px;
}

.GCP1rq_title {
  min-width: 105px;
  align-self: flex-start;
  margin-top: 15px;
  display: flex;
}

.GCP1rq_text {
  word-break: normal;
}

.GCP1rq_wrapper {
  width: 620px;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4px;
}

.GCP1rq_window {
  background-color: var(--light-green);
}

.GCP1rq_windowContents {
  line-height: 25px;
}

.GCP1rq_controlBtn {
}

.GCP1rq_guide {
  letter-spacing: .11px;
  margin: 0;
  font-size: 16px;
  line-height: 25px;
}

.GCP1rq_extensionWrapper {
  gap: 30px;
  margin: 26px 0 37px;
  display: flex;
}

.GCP1rq_linkToGuide {
  color: var(--web3-name);
  text-decoration: underline;
}

.GCP1rq_chromeExtension {
  height: 66px;
  max-width: 251px;
  width: 40%;
  border: 1px solid rgb(var(--extension-border-rgb) / 50%);
  color: #000;
  background: url("chrome_icon.d439fafd.svg") 10% 50% / 20% no-repeat;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.GCP1rq_firefoxExtension {
  background-image: url("firefox_icon.0235a727.svg");
}

.GCP1rq_linkTextSmall {
  color: var(--text);
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 50px;
  font-size: 11px;
  line-height: 10px;
  display: flex;
}

.GCP1rq_linkTextBig {
  font-size: 16px;
}

@media (max-width: 580px) {
  .GCP1rq_linkTextSmall {
    margin-left: 40px;
  }

  .GCP1rq_linkTextBig {
    font-size: 13px;
    line-height: 14px;
  }
}

.GCP1rq_list {
  letter-spacing: .1px;
  padding-left: 16px;
  font-size: 16px;
  list-style-type: "-";
}

.GCP1rq_listItem {
  margin: 0;
  padding-left: 8px;
  line-height: 25px;
}

.GCP1rq_listItem::marker {
  font-weight: normal;
}

.GCP1rq_onchainGuide {
  margin-bottom: .5rem;
}

.G6DEHa_container {
}

.G6DEHa_headingContainer {
  height: 80px;
}

.G6DEHa_privacyHeading {
  letter-spacing: .17px;
  font-size: 24px;
  font-weight: 300;
}

.G6DEHa_privacyContainer {
}

.G6DEHa_privacy {
  letter-spacing: .1px;
  font-size: 14px;
  line-height: 16px;
}

li::marker {
  font-size: 16px;
  font-weight: bolder;
}

.G6DEHa_spacedHeading {
}

.G6DEHa_bulletList {
}

.G6DEHa_anchor {
}

.vAko6q_container {
}

.vAko6q_headingContainer {
  height: 80px;
}

.vAko6q_heading {
  letter-spacing: .17px;
  font-size: 24px;
  font-weight: 300;
}

.vAko6q_imprintContainer {
}

.vAko6q_imprint {
  letter-spacing: .1px;
  font-size: 14px;
  line-height: 22px;
}

.vAko6q_imprintSection {
  margin: 16px 0 0;
}

.vAko6q_imprintLine {
  margin: 0;
}

.vAko6q_anchor {
  color: var(--web3-name);
  text-decoration: none;
}

/*# sourceMappingURL=index.8d1a6e34.css.map */
