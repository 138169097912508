.cardContainer {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: var(--header-secondary);
  width: 100%;
  box-shadow: 0 6px 8px 0 rgb(var(--black-rgb) / 25%);
  margin-bottom: 20px;
}

.expand {
  font-size: 16px;
  color: #fff;
  border: none;
  background: url('../../ImageAssets/open_chevron.svg') no-repeat center right
    7px/20px;
  text-align: left;
  cursor: pointer;
  height: 36px;
  padding-left: 20px;
}

.expand[aria-expanded='true'] {
  background-image: url('../../ImageAssets/close_chevron.svg');
}

.cardContents {
  margin: 0 4px 4px;
  padding: 28px 16px 16px;
  border-radius: 5px 5px 15px 15px;
  background-color: var(--contents);
}
