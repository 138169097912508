@font-face {
  font-family: Overpass;
  src: url('./Fonts/Overpass-Regular.woff2') format('woff2');
}

@font-face {
  font-family: Overpass;
  font-style: normal;
  font-weight: 600;
  src: url('./Fonts/Overpass-SemiBold.woff2') format('woff2');
}

:root {
  font-family: Overpass, sans-serif;
}

body {
  margin: 0;
}

html {
  --body: #fff;
  --web3-name: #2d8b00;
  --main-heading-background: #eaeaea;
  --header-secondary: #2d8b00;
  --header-bottom: #d8d8d8;
  --search-bar: #d8d8d8;
  --text: #0d0d0d;
  --header-text: #f3f3f3;
  --take-tour: #2d9403;
  --dark-grey: #322b2b;
  --link-disabled-rgb: 13 13 13;
  --fetch-rgb: 45 139 0;
  --gradient-1-3-rgb: 255 255 255;
  --gradient-1-2-rgb: 45 139 0;
  --separator-rgb: 69 69 69;
  --gradient-1-1-rgb: 45 139 0;
  --gradient-2-1-rgb: 37 102 6;
  --gradient-2-2-rgb: 22 48 10;
  --gradient-2-3-rgb: 13 13 13;
  --modal-bg: var(--header-text);
  --black-rgb: 0 0 0;
  --fetch-button: #f3f3f3;
  --fetch-button-text: #2d8b00;
  --fetch-button-border-disabled-rgb: 223 134 1;
  --fetch-button-text-disabled-rgb: 45 139 0;
  --light-green: #678956;
  --extension-border-rgb: var(--black-rgb);
  --contents: #f3f3f3;
  --modal-btn-disabled-rgb: 223 134 1;
  --footer: #f3f3f3;
  --inactive-tab: #eaeaea;
  --inactive-text: #c7c7c7;
  --button-primary-background: #f3f3f3;
  --button-primary-border: #df8601;
  --button-primary-text: #df8601;
  --select: #df8601;
  --select-text: #fff;
  --options-background: #f3f3f3;
  --modal-background: #f3f3f3;
  --modal-border: #3dc000;
  --inactive-payment-tab: #a3c791;
  --color-shadow-rgb: 0 0 0;
}

html.dark {
  --body: #000;
  --web3-name: #47e000;
  --main-heading-background: #242424;
  --header-secondary: #256606;
  --header-bottom: #454545;
  --search-bar: #d8d8d8;
  --text: #f3f3f3;
  --header-text: #f3f3f3;
  --take-tour: #2d7011;
  --link-disabled-rgb: 255 255 255;
  --footer: #181818;
  --modal-bg: #14280b;
  --fetch-button: #2d1f0b;
  --fetch-button-text: #f3f3f3;
  --fetch-button-text-disabled-rgb: 223 134 1;
  --extension-border-rgb: 255 255 255;
  --contents: #0d0d0d;
  --light-green: #3a5530;
  --inactive-tab: #6b6f69;
  --inactive-text: #f3f3f3;
  --inactive-payment-tab: #6b6f69;
  --button-primary-background: #2d1f0b;
  --button-primary-text: #f3f3f3;
  --select-text: #f3f3f3;
  --options-background: #2d1f0b;
  --modal-background: #14280b;
}
